<template>
	<div>
		<QAgentLookup
			v-model="agent"
			:filters="{ Status : 'Active,Available' }"
			:agent-attrs="['EquityPartnerInd']"
			return-object
			label="Agent"
			placeholder="Type to Search for Agent"
			hide-details
			preload-agents
			add-self
			:color="color"
		/>
		<div class="q-comprehensive-filter__filters-label mt-8">{{ item.label }}</div>
		<ComprehensiveAgencyStatsFilter
			v-model="statType"
			:agent="agent"
			with-master-agency
			:color="color"
		/>

		<div class="q-comprehensive-filter__filters-actions">
			<v-btn :class="actionBtnClass" outlined :color="color" @click="onCancel">
				Cancel
			</v-btn>
			<v-btn :class="actionBtnClass" :disabled="!valueChanged" :color="color" @click="onSave">
				{{ item.actionLabel || 'Add' }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import ComprehensiveAgencyStatsFilter from './ComprehensiveAgencyStatsFilter.vue'
import { statTypes } from './statTypes'

export default {
	components: {
		QAgentLookup,
		ComprehensiveAgencyStatsFilter,
	},
    props: {
		value: { type: Object, required: true },
		item: { type: Object, required: true },
		actionBtnClass: { type: String },
        color: { type: String },
	},
	data () {
		return {
			statType: statTypes.personal,
			agent: undefined,
		}
	},
	created () {
		this.updateInnerValues()
	},
    computed: {
		valueChanged () {
			const statTypeChanged = this.value.statType !== this.statType
			const agentChanged = this.value.Agent
				? this.value.Agent.AgentCode !== this.agent?.AgentCode
				: this.agent
					? this.agent.AgentCode !== this.user.Agent.AgentCode
					: false
			return statTypeChanged || agentChanged
		},
    },
	watch: {
		value () {
			this.updateInnerValues()
		},
	},
	methods: {
		updateInnerValues () {
			this.statType = this.value.statType
			this.agent = this.value.Agent || this.user.Agent
		},
		onCancel () {
			this.$emit('cancel')
		},
		onSave () {
			const value = {
				statType: this.statType,
				Agent: (this.agent && this.agent.AgentCode !== this.user.Agent.AgentCode) ? this.agent : undefined,
			}
			this.$emit('input', value)
			this.$emit('save')
		},
	},
}
</script>
