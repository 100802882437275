<template>
	<v-radio-group v-if="doShow" v-model="mValue" :disabled="disabled || loading" hide-details class="mt-2">
		<v-radio
			v-for="option in options" :key="option.value"
			:value="option.value"
			:label="option.label"
			:color="color"
		/>
	</v-radio-group>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'
import Level from '@/store/Models/AgentManagement/Level'

const leadershipLevelsDefaults = [
    'Managing Partner',
    'Senior Partner',
    'Associate Partner',
    'Executive Vice President',
    'Senior Vice President',
    'Managing Vice President',
    'Regional Agency Director',
    'Agency Director',
    'Agency Owner',
    'Key Leader',
    'Team Leader',
    'Sales Representative',
    'SFG',
    'Asurea',
    'Wholesale Agency',
    'Tenant',
    'Quility',
]

const stats = {
	totalAgency: 'TotalAgency',
	baseshop: 'Baseshop',
	personal: 'PersonalProduction',
	masterAgency: 'MasterAgency',
}

const defaultOptions = [
	{ label: 'Total Agency', value: stats.totalAgency },
	{ label: 'Baseshop', value: stats.baseshop },
	{ label: 'Personal', value: stats.personal },
	{ label: 'Master Agency', value: stats.masterAgency },
]

export default {
    props: {
		value: {
			type: String,
			default: stats.personal,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		withMasterAgency: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: 'primary',
		},
		agent: { type: Object },
	},
	data () {
		return {
			levelsLoading: false,
			agentDetailsLoading: false,
			hasEquityPartnerInd: false,
		}
	},
	created () {
		this.getLevels()
		if (this.withMasterAgency && !this.hasAdminPermissions) {
			this.getAgentDetails()
		}
	},
    computed: {
		mValue: {
			get () { return this.value },
			set (val) { this.$emit('input', val) },
		},
		loading () {
			return this.levelsLoading || this.agentDetailsLoading
		},
		leadershipLevels () {
			const levels = Level.query().where('LevelType', 'Leadership').get()
			if (!levels?.length) { return leadershipLevelsDefaults }
			return levels
				.sort((a, b) => b.LevelRank - a.LevelRank)
				.map(({ LevelName }) => LevelName)
		},
		mAgent () {
			return this.agent || this.user.Agent
		},
		leadershipLevel () {
			return this.mAgent.LeadershipLevel
		},
		leadershipOrderRelativeToAO () {
			const AOIndex = this.leadershipLevels.indexOf('Agency Owner')
			const agentLLIndex = this.leadershipLevels.indexOf(this.leadershipLevel)
			if (agentLLIndex === AOIndex) { return 0 }
			return agentLLIndex > AOIndex ? -1 : 1
		},
		initialOptions () {
			if (this.withMasterAgency) { return defaultOptions }
			return defaultOptions.filter(({ value }) => value !== stats.masterAgency)
		},
		availableOptions () {
			if (this.withMasterAgency && !this.hasEquityPartnerInd) {
				return defaultOptions.filter(({ value }) => value !== stats.masterAgency)
			}
			return this.initialOptions
		},
		hasAdminPermissions () {
			return this.hasRole(['SuperAdmin', 'Staff'])
		},
		options () {
			if (this.hasAdminPermissions) {
				return this.initialOptions
			}
			if (this.leadershipOrderRelativeToAO === 0) {
				return this.availableOptions.filter(({ value }) => value !== stats.totalAgency)
			}
			if (this.leadershipOrderRelativeToAO < 0) {
				return this.availableOptions.filter(({ value }) => value !== stats.baseshop)
			}
			return this.availableOptions
		},
		doShow () {
			return this.options.length > 1
		},
    },
	watch: {
		options: {
			deep: true,
			handler () {
				const exist = this.options.find(({ value }) => value === this.value)
				if (!exist) {
					this.mValue = stats.personal
				}
			},
		},
		agent () {
			if (this.withMasterAgency && !this.hasAdminPermissions) {
				this.getAgentDetails()
			}
		},
	},
	methods: {
		async getLevels () {
			this.levelsLoading = true
			try {
				await QuilityAPI.getAgentManagement_Levels()
			} catch (err) {
				console.error(err)
				// this.showError('Oops! There was a problem loading Leadership Levels.<br>', err.message)
			} finally {
				this.levelsLoading = false
			}
        },
		async getAgentDetails () {
			this.agentDetailsLoading = true
			try {
				const agent = await QuilityAPI.getAgentStateless(this.mAgent.AgentCode, ['EquityPartnerInd'])
				this.hasEquityPartnerInd = !!agent.EquityPartnerInd
			} catch (err) {
				console.error(err)
				this.showError('Oops! There was a problem loading agent details.<br>', err.message)
			} finally {
				this.agentDetailsLoading = false
			}
        },
	},
}
</script>
